import { analytics } from '@src/lib/analytics';
import * as sunshineService from '@src/lib/sunshine';
import {
  CookieName,
  getUser,
  serializeCookie,
  serializeUnsetUser,
  setCookie,
  setUser,
  unsetUser,
} from '@src/utils/cookies';
import type { CookieUser } from '@src/utils/cookies/user';
import type { NextRouter } from 'next/router';
import type { GetServerSidePropsContext } from 'next';
import { notifyError } from './error-reporter';

const clientOnly =
  <T extends (...args: never[]) => unknown>(fn: T) =>
  (...args: Parameters<T>) => {
    if (typeof window !== 'undefined') {
      fn(...args);
    }
  };

/**
 * Checks and notify if we're changing users without logout first,
 * which means that a user might be taking another user
 * account somehow.
 *
 * If you get reports from this check how this happened ASAP.
 */
const checkAndNotifySessionMismatch = <T extends { uuid: string }>(user: T) => {
  const current = getUser();
  const mismatch = current && current.uuid !== user.uuid;

  if (mismatch) {
    notifyError('Session mismatch detected', {
      extraSections: { session: { current, user } },
    });
  }

  return mismatch;
};

// Warning: this is not called when the user cookie expires, as the getUser
// will return undefined at the server side and user will be redirected
// straight to login, without the reset calls.
export const resetSession = clientOnly(() => {
  analytics.reset();

  sunshineService?.destroy();

  unsetUser();
});

export const initializeSession = clientOnly((user: CookieUser) => {
  // for security reasons it's better to logout.
  if (checkAndNotifySessionMismatch(user)) {
    resetSession();
  } else {
    analytics.identifyUser(user);
    setUser(user);
  }
});

export const updateSession = clientOnly(
  (data: Partial<CookieUser> & { uuid: string }) => {
    const user = getUser();

    // for security reasons it's better to logout.
    if (!user || checkAndNotifySessionMismatch(data)) {
      resetSession();
    } else {
      initializeSession({
        uuid: data.uuid || user.uuid,
        authToken: data.authToken || user.authToken,
        email: data.email || user.email,
        firstName: data.firstName || user.firstName,
        intercomUserHash: data.intercomUserHash || user.intercomUserHash,
        lastName: data.lastName || user.lastName,
        state: data.state || user.state,
        createdAt: data.createdAt || user.createdAt,
        preferredName: data.preferredName || user.preferredName,
      });
    }
  }
);

export const resetSessionToLogin = (router: NextRouter) => {
  // We use window location because it's used in a place where the router.asPath is not updated
  // We can use the router.asPath after removing our _apolloClient singleton
  const { pathname } = window.location;
  if (pathname !== '/') {
    setCookie(CookieName.AfterAuthRedirect, pathname);
  }

  resetSession();

  // Here we use router to not reload the page and keep it working as an SPA.
  router.replace({ pathname: '/login', query: router.query });
};

export function ssrResetSessionToLogin(ctx: GetServerSidePropsContext) {
  if (!ctx.res) return;

  const cookies = [serializeUnsetUser()];

  if (ctx.req.url && ctx.req.url !== '/') {
    cookies.push(serializeCookie(CookieName.AfterAuthRedirect, ctx.req.url));
  }

  ctx.res.writeHead(302, {
    // TODO - update this to no longer reference a private property
    Location: `/login${(ctx.req as any)._parsedUrl?.search ?? ''}`,
    'Set-Cookie': cookies,
  });
  ctx.res.end();
}
