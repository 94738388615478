export { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAddressCard as falAddressCard } from '@fortawesome/pro-light-svg-icons/faAddressCard';
import { faAlarmClock as falAlarmClock } from '@fortawesome/pro-light-svg-icons/faAlarmClock';
import { faAngleDown as falAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faAngleDown as farAngleDown } from '@fortawesome/pro-regular-svg-icons/faAngleDown';
import { faAngleLeft as falAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faAngleUp as falAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown';
import { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp';
import { faArrowUpFromBracket as farArrowUpFromBracket } from '@fortawesome/pro-regular-svg-icons/faArrowUpFromBracket';
import { faBan as falBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faBars as falBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBed as falBed } from '@fortawesome/pro-light-svg-icons/faBed';
import { faBell as falBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { faBicycle as falBicycle } from '@fortawesome/pro-light-svg-icons/faBicycle';
import { faBinoculars as falBinoculars } from '@fortawesome/pro-light-svg-icons/faBinoculars';
import { faBookHeart as falBookHeart } from '@fortawesome/pro-light-svg-icons/faBookHeart';
import { faBookmark as falBookmark } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faBookmark as fasBookmark } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { faBooks as falBooks } from '@fortawesome/pro-light-svg-icons/faBooks';
import { faBriefcase as falBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faBriefcaseMedical as falBriefcaseMedical } from '@fortawesome/pro-light-svg-icons/faBriefcaseMedical';
import { faBuilding as falBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faBullhorn as falBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faBullhorn as farBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn';
import { faBusinessTime as falBusinessTime } from '@fortawesome/pro-light-svg-icons/faBusinessTime';
import { faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faCalendarAlt as falCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faCalendarCheck as falCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck';
import { faCalendarDay as falCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faCalendarEdit as falCalendarEdit } from '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import { faCalendarLines as falCalendarLines } from '@fortawesome/pro-light-svg-icons/faCalendarLines';
import { faCaretDown as fasCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp as fasCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faCar as falCar } from '@fortawesome/pro-light-svg-icons/faCar';
import { faChartPieAlt as falChartPieAlt } from '@fortawesome/pro-light-svg-icons/faChartPieAlt';
import { faCheck as falCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCheck as fasCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckSquare as falCheckSquare } from '@fortawesome/pro-light-svg-icons/faCheckSquare';
import { faCheckCircle as fadCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { faCheckCircle as falCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faCheckCircle as fasCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faChevronCircleRight as falChevronCircleRight } from '@fortawesome/pro-light-svg-icons/faChevronCircleRight';
import { faChevronCircleLeft as falChevronCircleLeft } from '@fortawesome/pro-light-svg-icons/faChevronCircleLeft';
import { faChevronDown as falChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronLeft as falChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight as falChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronUp as falChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faCircle as falCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faCircle as fasCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCircleInfo as fasCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import { faCirclePlay as fasCirclePlay } from '@fortawesome/pro-solid-svg-icons/faCirclePlay';
import { faCirclePlay as falCirclePlay } from '@fortawesome/pro-light-svg-icons/faCirclePlay';
import { faClipboard as falClipboard } from '@fortawesome/pro-light-svg-icons/faClipboard';
import { faClipboardCheck as falClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faClipboardList as falClipboardList } from '@fortawesome/pro-light-svg-icons/faClipboardList';
import { faClipboardListCheck as fadClipboardListCheck } from '@fortawesome/pro-duotone-svg-icons/faClipboardListCheck';
import { faClipboardListCheck as falClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck';
import { faClock as falClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faCloud as falCloud } from '@fortawesome/pro-light-svg-icons/faCloud';
import { faCloudDownload as falCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faCloudUpload as falCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { faCog as falCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faComment as falComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faCommentAltSmile as falCommentAltSmile } from '@fortawesome/pro-light-svg-icons/faCommentAltSmile';
import { faCommentDots as fasCommentDots } from '@fortawesome/pro-solid-svg-icons/faCommentDots';
import { faCompass as falCompass } from '@fortawesome/pro-light-svg-icons/faCompass';
import { faCopy as falCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faDollarSign as falDollarSign } from '@fortawesome/pro-light-svg-icons/faDollarSign';
import { faEclipse as falEclipse } from '@fortawesome/pro-light-svg-icons/faEclipse';
import { faEclipse as fasEclipse } from '@fortawesome/pro-solid-svg-icons/faEclipse';
import { faEnvelope as fadEnvelope } from '@fortawesome/pro-duotone-svg-icons/faEnvelope';
import {
  faEnvelope as falEnvelope,
  faEnvelope as falFaEnvelope,
} from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faEnvelopeOpenText as falEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faExclamationCircle as falExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faExclamationTriangle as falExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faExternalLink as falExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faExternalLink as farExternalLink } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faEye as falEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faApple as fabApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faFacebook as fabFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faFacebookF as fabFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookMessenger as fabFacebookMessenger } from '@fortawesome/free-brands-svg-icons/faFacebookMessenger';
import { faFacebookSquare as fabFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faFile as falFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileAlt as falFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faFileCertificate as falFileCertificate } from '@fortawesome/pro-light-svg-icons/faFileCertificate';
import { faFileCheck as falFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faFileExport as falFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faFileImport as falFileImport } from '@fortawesome/pro-light-svg-icons/faFileImport';
import { faFileInvoiceDollar as falFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons/faFileInvoiceDollar';
import { faFileMedical as falFileFileMedical } from '@fortawesome/pro-light-svg-icons/faFileMedical';
import { faFileSignature as falFileSignature } from '@fortawesome/pro-light-svg-icons/faFileSignature';
import { faFilter as falFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faFlag as falFlag } from '@fortawesome/pro-light-svg-icons/faFlag';
import { faFlowerTulip as falFlowerTulip } from '@fortawesome/pro-light-svg-icons/faFlowerTulip';
import { faFolders as falFolders } from '@fortawesome/pro-light-svg-icons/faFolders';
import { faGift as falGift } from '@fortawesome/pro-light-svg-icons/faGift';
import { faGifts as falGifts } from '@fortawesome/pro-light-svg-icons/faGifts';
import { faGlassCheers as falGlassCheers } from '@fortawesome/pro-light-svg-icons/faGlassCheers';
import { faGlassesAlt as falGlassesAlt } from '@fortawesome/pro-light-svg-icons/faGlassesAlt';
import { faGlassMartini as falGlassMartini } from '@fortawesome/pro-light-svg-icons/faGlassMartini';
import { faGlobe as falGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faGlobeAmericas as falGlobeAmericas } from '@fortawesome/pro-light-svg-icons/faGlobeAmericas';
import { faGraduationCap as falGraduationCap } from '@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faHandPaper as fasHandPaper } from '@fortawesome/pro-solid-svg-icons/faHandPaper';
import { faHeadset as falHeadset } from '@fortawesome/pro-light-svg-icons/faHeadset';
import { faHeart as falHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faHeartbeat as falHeartbeat } from '@fortawesome/pro-light-svg-icons/faHeartbeat';
import { faHistory as falHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faHome as falHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faHospital as falHospital } from '@fortawesome/pro-light-svg-icons/faHospital';
import { faHospitalAlt as falHospitalAlt } from '@fortawesome/pro-light-svg-icons/faHospitalAlt';
import { faHospitals as falHospitals } from '@fortawesome/pro-light-svg-icons/faHospitals';
import { faHospitalUser as falHospitalUser } from '@fortawesome/pro-light-svg-icons/faHospitalUser';
import { faHourglassHalf as falHourglassHalf } from '@fortawesome/pro-light-svg-icons/faHourglassHalf';
import { faHSquare as falHSquare } from '@fortawesome/pro-light-svg-icons/faHSquare';
import { faIdBadge as fadIdBadge } from '@fortawesome/pro-duotone-svg-icons/faIdBadge';
import { faIdCard as falIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard';
import { faIdCardAlt as fadIdCardAlt } from '@fortawesome/pro-duotone-svg-icons/faIdCardAlt';
import { faIdCardAlt as falIdCardAlt } from '@fortawesome/pro-light-svg-icons/faIdCardAlt';
import { faInfoCircle as falInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faInfoSquare as falInfoSquare } from '@fortawesome/pro-light-svg-icons/faInfoSquare';
import { faIslandTropical as falIslandTropical } from '@fortawesome/pro-light-svg-icons/faIslandTropical';
import { faIslandTropical as fasIslandTropical } from '@fortawesome/pro-solid-svg-icons/faIslandTropical';
import { faInstagram as fabInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faKey as falKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faLaptop as falLaptop } from '@fortawesome/pro-light-svg-icons/faLaptop';
import { faLemon as falLemon } from '@fortawesome/pro-light-svg-icons/faLemon';
import { faLifeRing as falLifeRing } from '@fortawesome/pro-light-svg-icons/faLifeRing';
import { faLightbulb as falLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb';
import { faLinkedinIn as fabLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faLock as falLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faLockAlt as falLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { faMapMarkedAlt as falMapMarkedAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkedAlt';
import { faMapMarkerAlt as falMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faMapPin as fasMapPin } from '@fortawesome/pro-solid-svg-icons/faMapPin';
import { faMedkit as falMedkit } from '@fortawesome/pro-light-svg-icons/faMedkit';
import { faMessageSms as falMessageSms } from '@fortawesome/pro-light-svg-icons/faMessageSms';
import { faMobile as falMobile } from '@fortawesome/pro-light-svg-icons/faMobile';
import { faMoneyBillWave as falMoneyBillWave } from '@fortawesome/pro-light-svg-icons/faMoneyBillWave';
import { faMoneyCheckAlt as falMoneyCheckAlt } from '@fortawesome/pro-light-svg-icons/faMoneyCheckAlt';
import { faMoneyCheckAlt as fasMoneyCheckAlt } from '@fortawesome/pro-solid-svg-icons/faMoneyCheckAlt';
import { faMoon as falMoon } from '@fortawesome/pro-light-svg-icons/faMoon';
import { faMoonStars as fasMoonStars } from '@fortawesome/pro-solid-svg-icons/faMoonStars';
import { faNewspaper as falNewspaper } from '@fortawesome/pro-light-svg-icons/faNewspaper';
import { faPaperclip as falPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faPaperPlane as falPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { faPen as falFaPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faPen as fasFaPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faPencil as falFaPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faPhone as falFaPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faMessage as falFaMessage } from '@fortawesome/pro-light-svg-icons/faMessage';
import { faPhoneLaptop as falPhoneLaptop } from '@fortawesome/pro-light-svg-icons/faPhoneLaptop';
import { faPhoneRotary as fadPhoneRotary } from '@fortawesome/pro-duotone-svg-icons/faPhoneRotary';
import { faPlane as falPlane } from '@fortawesome/pro-light-svg-icons/faPlane';
import { faPlane as fasPlane } from '@fortawesome/pro-solid-svg-icons/faPlane';
import { faPlaneAlt as falPlaneAlt } from '@fortawesome/pro-light-svg-icons/faPlaneAlt';
import { faPlaneAlt as fasPlaneAlt } from '@fortawesome/pro-solid-svg-icons/faPlaneAlt';
import { faPlus as falPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faPlusCircle as falPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faPrintSearch as falPrintSearch } from '@fortawesome/pro-light-svg-icons/faPrintSearch';
import { faQuestionCircle as falQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faRabbitFast as falRabbitFast } from '@fortawesome/pro-light-svg-icons/faRabbitFast';
import { faRabbitFast as fasRabbitFast } from '@fortawesome/pro-solid-svg-icons/faRabbitFast';
import { faRaindrops as falRaindrops } from '@fortawesome/pro-light-svg-icons/faRaindrops';
import { faRocket as falRocket } from '@fortawesome/pro-light-svg-icons/faRocket';
import { faRss as falRss } from '@fortawesome/pro-light-svg-icons/faRss';
import { faSackDollar as falSackDollar } from '@fortawesome/pro-light-svg-icons/faSackDollar';
import { faSave as falSave } from '@fortawesome/pro-light-svg-icons/faSave';
import { faSearch as falSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSearchDollar as falSearchDollar } from '@fortawesome/pro-light-svg-icons/faSearchDollar';
import { faShieldCheck as falShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faSignOut as falSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faSlidersH as falSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH';
import { faSolarSystem as falSolarSystem } from '@fortawesome/pro-light-svg-icons/faSolarSystem';
import { faSnowflakes as falSnowflakes } from '@fortawesome/pro-light-svg-icons/faSnowflakes';
import { faSpinner as falSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faSquare as falSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faSquare as fasSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { faStar as falStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faStar as fasStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faStars as fasStars } from '@fortawesome/pro-solid-svg-icons/faStars';
import { faStars as farStars } from '@fortawesome/pro-regular-svg-icons/faStars';
import { faStethoscope as falStethoscope } from '@fortawesome/pro-light-svg-icons/faStethoscope';
import { faSubway as falSubway } from '@fortawesome/pro-light-svg-icons/faSubway';
import { faSuitcase as fadSuitcase } from '@fortawesome/pro-duotone-svg-icons/faSuitcase';
import { faSuitcase as falSuitcase } from '@fortawesome/pro-light-svg-icons/faSuitcase';
import { faSuitcase as fasSuitcase } from '@fortawesome/pro-solid-svg-icons/faSuitcase';
import { faSun as falSun } from '@fortawesome/pro-light-svg-icons/faSun';
import { faSun as fasSun } from '@fortawesome/pro-solid-svg-icons/faSun';
import { faSunCloud as falSunCloud } from '@fortawesome/pro-light-svg-icons/faSunCloud';
import { faSunHaze as falSunHaze } from '@fortawesome/pro-light-svg-icons/faSunHaze';
import { faSunset as falSunset } from '@fortawesome/pro-light-svg-icons/faSunset';
import { faSunset as fasSunset } from '@fortawesome/pro-solid-svg-icons/faSunset';
import { faTabletAlt as falTabletAlt } from '@fortawesome/pro-light-svg-icons/faTabletAlt';
import { faTachometerAltFastest as farTachometerAltFastest } from '@fortawesome/pro-regular-svg-icons/faTachometerAltFastest';
import { faTasks as farTasks } from '@fortawesome/pro-regular-svg-icons/faTasks';
import { faTasks as fasListCheck } from '@fortawesome/pro-solid-svg-icons/faTasks';
import { faTelescope as falTelescope } from '@fortawesome/pro-light-svg-icons/faTelescope';
import { faThermometerHalf as falThermometerHalf } from '@fortawesome/pro-light-svg-icons/faThermometerHalf';
import { faThLarge as falThLarge } from '@fortawesome/pro-light-svg-icons/faThLarge';
import { faThumbtack as falThumbtack } from '@fortawesome/pro-light-svg-icons/faThumbtack';
import { faThumbtack as fasThumbtack } from '@fortawesome/pro-solid-svg-icons/faThumbtack';
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTimesCircle as falTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faTimesCircle as fasTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faTooth as falTooth } from '@fortawesome/pro-light-svg-icons/faTooth';
import { faTrashAlt as falTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faTree as falTree } from '@fortawesome/pro-light-svg-icons/faTree';
import { faTrophy as falTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';
import { faTshirt as falTshirt } from '@fortawesome/pro-light-svg-icons/faTShirt';
import { faTwitter as fabTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faUmbrellaBeach as falUmbrellaBeach } from '@fortawesome/pro-light-svg-icons/faUmbrellaBeach';
import { faUnlockAlt as farUnlockAlt } from '@fortawesome/pro-regular-svg-icons/faUnlockAlt';
import { faUser as falUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUserDoctor as falUserDoctor } from '@fortawesome/pro-light-svg-icons/faUserDoctor';
import { faUserCheck as farUserCheck } from '@fortawesome/pro-regular-svg-icons/faUserCheck';
import { faUserClock as fasUserClock } from '@fortawesome/pro-solid-svg-icons/faUserClock';
import { faUserEdit as fadUserEdit } from '@fortawesome/pro-duotone-svg-icons/faUserEdit';
import { faUserGraduate as falUserGraduate } from '@fortawesome/pro-light-svg-icons/faUserGraduate';
import { faUserMd as falUserMd } from '@fortawesome/pro-light-svg-icons/faUserMd';
import { faUserNurse as falUserNurse } from '@fortawesome/pro-light-svg-icons/faUserNurse';
import { faUsers as falUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faUsersMedical as falUsersMedical } from '@fortawesome/pro-light-svg-icons/faUsersMedical';
import { faUtensilsAlt as falUtensilsAlt } from '@fortawesome/pro-light-svg-icons/faUtensilsAlt';
import { faWalking as falWalking } from '@fortawesome/pro-light-svg-icons/faWalking';
import { faWalking as fasWalking } from '@fortawesome/pro-solid-svg-icons/faWalking';
import { faWhatsapp as fabWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { faAddressCard as fasAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import { faAlarmClock as fasAlarmClock } from '@fortawesome/pro-solid-svg-icons/faAlarmClock';
import { faAngleDown as fasAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import { faAngleLeft as fasAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import { faAngleRight as fasAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight';
import { faAngleUp as fasAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp';
import { faShareAlt as farShareAlt } from '@fortawesome/pro-regular-svg-icons/faShareAlt';
import { faShare as fasShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faBan as fasBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faBars as fasBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faBed as fasBed } from '@fortawesome/pro-solid-svg-icons/faBed';
import { faBell as fasBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faBellOn as fasBellOn } from '@fortawesome/pro-solid-svg-icons/faBellOn';
import { faSparkles as fasSparkles } from '@fortawesome/pro-solid-svg-icons/faSparkles';
import { faBicycle as fasBicycle } from '@fortawesome/pro-solid-svg-icons/faBicycle';
import { faBinoculars as fasBinoculars } from '@fortawesome/pro-solid-svg-icons/faBinoculars';
import { faBookHeart as fasBookHeart } from '@fortawesome/pro-solid-svg-icons/faBookHeart';
import { faBooks as fasBooks } from '@fortawesome/pro-solid-svg-icons/faBooks';
import { faBriefcase as fasBriefcase } from '@fortawesome/pro-solid-svg-icons/faBriefcase';
import { faBriefcaseMedical as fasBriefcaseMedical } from '@fortawesome/pro-solid-svg-icons/faBriefcaseMedical';
import { faBuilding as fasBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faBullhorn as fasBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn';
import { faBusinessTime as fasBusinessTime } from '@fortawesome/pro-solid-svg-icons/faBusinessTime';
import { faCalendar as fasCalendar } from '@fortawesome/pro-solid-svg-icons/faCalendar';
import { faCalendarAlt as fasCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faCalendarCheck as fasCalendarCheck } from '@fortawesome/pro-solid-svg-icons/faCalendarCheck';
import { faCalendarDay as fasCalendarDay } from '@fortawesome/pro-solid-svg-icons/faCalendarDay';
import { faCalendarEdit as fasCalendarEdit } from '@fortawesome/pro-solid-svg-icons/faCalendarEdit';
import { faCar as fasCar } from '@fortawesome/pro-solid-svg-icons/faCar';
import { faChartPieAlt as fasChartPieAlt } from '@fortawesome/pro-solid-svg-icons/faChartPieAlt';
import { faChevronRight as fasChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronDown as fasChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronUp as fasChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faClipboardCheck as fasClipboardCheck } from '@fortawesome/pro-solid-svg-icons/faClipboardCheck';
import { faClipboardList as fasClipboardList } from '@fortawesome/pro-solid-svg-icons/faClipboardList';
import { faClipboardListCheck as fasClipboardListCheck } from '@fortawesome/pro-solid-svg-icons/faClipboardListCheck';
import { faClock as fasClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faCloud as fasCloud } from '@fortawesome/pro-solid-svg-icons/faCloud';
import { faCloudDownload as fasCloudDownload } from '@fortawesome/pro-solid-svg-icons/faCloudDownload';
import { faCloudUpload as fasCloudUpload } from '@fortawesome/pro-solid-svg-icons/faCloudUpload';
import { faCog as fasCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faComment as fasComment } from '@fortawesome/pro-solid-svg-icons/faComment';
import { faCommentAlt as falCommentAlt } from '@fortawesome/pro-light-svg-icons/faCommentAlt';
import { faCommentAltSmile as fasCommentAltSmile } from '@fortawesome/pro-solid-svg-icons/faCommentAltSmile';
import { faCompass as fasCompass } from '@fortawesome/pro-solid-svg-icons/faCompass';
import { faCopy as fasCopy } from '@fortawesome/pro-solid-svg-icons/faCopy';
import { faDollarSign as fasDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign';
import { faEnvelope as fasEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faEnvelopeOpenText as fasEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons/faEnvelopeOpenText';
import { faExclamation as fasExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle as fasExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faExternalLink as fasExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { faEye as fasEye } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faPencil as fasFaPencil } from '@fortawesome/pro-solid-svg-icons/faPencil';
import { faFile as fasFile } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faFileAlt as fasFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faFileDownload as fasFileDownload } from '@fortawesome/pro-solid-svg-icons/faFileDownload';
import { faFileCertificate as fasFileCertificate } from '@fortawesome/pro-solid-svg-icons/faFileCertificate';
import { faFileCheck as fasFileCheck } from '@fortawesome/pro-solid-svg-icons/faFileCheck';
import { faFileExclamation as fasFileExclamation } from '@fortawesome/pro-solid-svg-icons/faFileExclamation';
import { faFileMedical as fasFileFileMedical } from '@fortawesome/pro-solid-svg-icons/faFileMedical';
import { faFileImport as fasFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faFileInvoiceDollar as fasFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar';
import { faFilesMedical as fasFilesMedical } from '@fortawesome/pro-solid-svg-icons/faFilesMedical';
import { faFileSignature as fasFileSignature } from '@fortawesome/pro-solid-svg-icons/faFileSignature';
import { faFilter as fasFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faFlag as fasFlag } from '@fortawesome/pro-solid-svg-icons/faFlag';
import { faFlagCheckered as fasFlagCheckered } from '@fortawesome/pro-solid-svg-icons/faFlagCheckered';
import { faFlowerTulip as fasFlowerTulip } from '@fortawesome/pro-solid-svg-icons/faFlowerTulip';
import { faFolders as fasFolders } from '@fortawesome/pro-solid-svg-icons/faFolders';
import { faGift as fasGift } from '@fortawesome/pro-solid-svg-icons/faGift';
import { faGifts as fasGifts } from '@fortawesome/pro-solid-svg-icons/faGifts';
import { faGlassCheers as fasGlassCheers } from '@fortawesome/pro-solid-svg-icons/faGlassCheers';
import { faGlassesAlt as fasGlassesAlt } from '@fortawesome/pro-solid-svg-icons/faGlassesAlt';
import { faGlassMartini as fasGlassMartini } from '@fortawesome/pro-solid-svg-icons/faGlassMartini';
import { faGlobe as fasGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { faGlobeAmericas as fasGlobeAmericas } from '@fortawesome/pro-solid-svg-icons/faGlobeAmericas';
import { faGoogle as fabGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faGraduationCap as fasGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import { faHeadset as fasHeadset } from '@fortawesome/pro-solid-svg-icons/faHeadset';
import { faHeartbeat as fasHeartbeat } from '@fortawesome/pro-solid-svg-icons/faHeartbeat';
import { faHistory as fasHistory } from '@fortawesome/pro-solid-svg-icons/faHistory';
import { faHome as fasHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faHospital as fasHospital } from '@fortawesome/pro-solid-svg-icons/faHospital';
import { faHospitalAlt as fasHospitalAlt } from '@fortawesome/pro-solid-svg-icons/faHospitalAlt';
import { faHospitals as fasHospitals } from '@fortawesome/pro-solid-svg-icons/faHospitals';
import { faHospitalUser as fasHospitalUser } from '@fortawesome/pro-solid-svg-icons/faHospitalUser';
import { faHourglassHalf as fasHourglassHalf } from '@fortawesome/pro-solid-svg-icons/faHourglassHalf';
import { faHSquare as fasHSquare } from '@fortawesome/pro-solid-svg-icons/faHSquare';
import { faIdCard as fasIdCard } from '@fortawesome/pro-solid-svg-icons/faIdCard';
import { faIdCardAlt as fasIdCardAlt } from '@fortawesome/pro-solid-svg-icons/faIdCardAlt';
import { faInfoCircle as fasInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faInfoSquare as fasInfoSquare } from '@fortawesome/pro-solid-svg-icons/faInfoSquare';
import { faKey as fasKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faLaptop as fasLaptop } from '@fortawesome/pro-solid-svg-icons/faLaptop';
import { faLemon as fasLemon } from '@fortawesome/pro-solid-svg-icons/faLemon';
import { faLifeRing as fasLifeRing } from '@fortawesome/pro-solid-svg-icons/faLifeRing';
import { faLightbulb as fasLightbulb } from '@fortawesome/pro-solid-svg-icons/faLightbulb';
import { faLockAlt as fasLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faMapMarkedAlt as fasMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkedAlt';
import { faMapMarkerAlt as fasMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faMedkit as fasMedkit } from '@fortawesome/pro-solid-svg-icons/faMedkit';
import { faMobile as fasMobile } from '@fortawesome/pro-solid-svg-icons/faMobile';
import { faMoneyBillWave as fasMoneyBillWave } from '@fortawesome/pro-solid-svg-icons/faMoneyBillWave';
import { faMoon as fasMoon } from '@fortawesome/pro-solid-svg-icons/faMoon';
import { faNewspaper as fasNewspaper } from '@fortawesome/pro-solid-svg-icons/faNewspaper';
import { faPaperclip as fasPaperclip } from '@fortawesome/pro-solid-svg-icons/faPaperclip';
import { faPaperPlane as fasPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane';
import { faPhoneLaptop as fasPhoneLaptop } from '@fortawesome/pro-solid-svg-icons/faPhoneLaptop';
import { faPlus as fasPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faPlusCircle as fasPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faPrintSearch as fasPrintSearch } from '@fortawesome/pro-solid-svg-icons/faPrintSearch';
import { faQuestionCircle as fasQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faRaindrops as fasRaindrops } from '@fortawesome/pro-solid-svg-icons/faRaindrops';
import { faRocket as fasRocket } from '@fortawesome/pro-solid-svg-icons/faRocket';
import { faRss as fasRss } from '@fortawesome/pro-solid-svg-icons/faRss';
import { faSackDollar as fasSackDollar } from '@fortawesome/pro-solid-svg-icons/faSackDollar';
import { faSave as fasSave } from '@fortawesome/pro-solid-svg-icons/faSave';
import { faSearch as fasSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faSearchDollar as fasSearchDollar } from '@fortawesome/pro-solid-svg-icons/faSearchDollar';
import { faShieldCheck as fasShieldCheck } from '@fortawesome/pro-solid-svg-icons/faShieldCheck';
import { faSignOut as fasSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut';
import { faSlidersH as fasSlidersH } from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import { faSlidersV as farSlidersV } from '@fortawesome/pro-regular-svg-icons/faSlidersV';
import { faSnowflakes as fasSnowflakes } from '@fortawesome/pro-solid-svg-icons/faSnowflakes';
import { faSolarSystem as fasSolarSystem } from '@fortawesome/pro-solid-svg-icons/faSolarSystem';
import { faSort as fasSort } from '@fortawesome/pro-solid-svg-icons/faSort';
import { faSpinner as fasSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faStethoscope as fasStethoscope } from '@fortawesome/pro-solid-svg-icons/faStethoscope';
import { faSubway as fasSubway } from '@fortawesome/pro-solid-svg-icons/faSubway';
import { faSunCloud as fasSunCloud } from '@fortawesome/pro-solid-svg-icons/faSunCloud';
import { faSunHaze as fasSunHaze } from '@fortawesome/pro-solid-svg-icons/faSunHaze';
import { faTabletAlt as fasTabletAlt } from '@fortawesome/pro-solid-svg-icons/faTabletAlt';
import { faTelescope as fasTelescope } from '@fortawesome/pro-solid-svg-icons/faTelescope';
import { faThermometerHalf as fasThermometerHalf } from '@fortawesome/pro-solid-svg-icons/faThermometerHalf';
import { faThLarge as fasThLarge } from '@fortawesome/pro-solid-svg-icons/faThLarge';
import { faTimes as fasTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTools as fasTools } from '@fortawesome/pro-solid-svg-icons/faTools';
import { faTooth as fasTooth } from '@fortawesome/pro-solid-svg-icons/faTooth';
import { faTrashAlt as fasTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt';
import { faTree as fasTree } from '@fortawesome/pro-solid-svg-icons/faTree';
import { faTrophy as fasTrophy } from '@fortawesome/pro-solid-svg-icons/faTrophy';
import { faTshirt as fasTshirt } from '@fortawesome/pro-solid-svg-icons/faTShirt';
import { faUmbrellaBeach as fasUmbrellaBeach } from '@fortawesome/pro-solid-svg-icons/faUmbrellaBeach';
import { faUser as fasUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsersCog as fasUsersCog } from '@fortawesome/pro-solid-svg-icons/faUsersCog';
import { faUserGraduate as fasUserGraduate } from '@fortawesome/pro-solid-svg-icons/faUserGraduate';
import { faUserMd as fasUserMd } from '@fortawesome/pro-solid-svg-icons/faUserMd';
import { faUserNurse as fasUserNurse } from '@fortawesome/pro-solid-svg-icons/faUserNurse';
import { faUsers as fasUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { faUsersMedical as fasUsersMedical } from '@fortawesome/pro-solid-svg-icons/faUsersMedical';
import { faUtensilsAlt as fasUtensilsAlt } from '@fortawesome/pro-solid-svg-icons/faUtensilsAlt';
import { faYahoo as fabYahoo } from '@fortawesome/free-brands-svg-icons/faYahoo';
import { faCommentLines as falCommentLines } from '@fortawesome/pro-light-svg-icons/faCommentLines';
import { faBallotCheck as fasBallotCheck } from '@fortawesome/pro-solid-svg-icons/faBallotCheck';
import { faPenToSquare as farPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import { faListUl as farListUl } from '@fortawesome/pro-regular-svg-icons/faListUl';
import { faArrowUpRightFromSquare as fasArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';
import { faTrashAlt as farTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';

// https://github.com/FortAwesome/Font-Awesome/issues/19348
// https://github.com/FortAwesome/react-fontawesome/issues/525
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { library } = require('@fortawesome/fontawesome-svg-core');

library.add(
  farShareAlt,
  fasShare,
  fabApple,
  fabFacebook,
  fabFacebookF,
  fabFacebookMessenger,
  fabFacebookSquare,
  fabGoogle,
  fabInstagram,
  fabLinkedinIn,
  fabTwitter,
  fabWhatsapp,
  fabYahoo,
  fadCheckCircle,
  fadClipboardListCheck,
  fadEnvelope,
  fadIdBadge,
  fadIdCardAlt,
  fadPhoneRotary,
  fadSuitcase,
  fadUserEdit,
  falAddressCard,
  falAlarmClock,
  falAngleDown,
  falAngleLeft,
  falAngleRight,
  falAngleUp,
  falBan,
  falBars,
  falBed,
  falBell,
  falBicycle,
  falBinoculars,
  falBookHeart,
  falBookmark,
  falBooks,
  falBriefcase,
  falBriefcaseMedical,
  falBuilding,
  falBullhorn,
  falBusinessTime,
  falCalendar,
  falCalendarAlt,
  falCalendarCheck,
  falCalendarDay,
  falCalendarEdit,
  falCalendarLines,
  falCar,
  falChartPieAlt,
  falCheck,
  falCheckCircle,
  falCheckSquare,
  falChevronRight,
  falCircle,
  falClipboard,
  falClipboardCheck,
  falClipboardList,
  falClipboardListCheck,
  falClock,
  falCloud,
  falCloudDownload,
  falCloudUpload,
  falCog,
  falComment,
  falCommentAlt,
  falCommentAltSmile,
  falCompass,
  falCopy,
  falDollarSign,
  falEclipse,
  falEnvelope,
  falEnvelopeOpenText,
  falExclamationCircle,
  falExclamationTriangle,
  falExternalLink,
  falEye,
  falFaPen,
  falFaPencil,
  falFaPhone,
  falFaMessage,
  falFaEnvelope,
  falFile,
  falFileAlt,
  falFileCertificate,
  falFileCheck,
  falFileExport,
  falFileFileMedical,
  falFileImport,
  falFileInvoiceDollar,
  falFileSignature,
  falFilter,
  falFlag,
  falFlowerTulip,
  falFolders,
  falGift,
  falGifts,
  falGlassCheers,
  falGlassesAlt,
  falGlassMartini,
  falGlobe,
  falGlobeAmericas,
  falGraduationCap,
  falHeadset,
  falHeart,
  falHeartbeat,
  falHistory,
  falHome,
  falHospital,
  falHospitalAlt,
  falHospitals,
  falHospitalUser,
  falHourglassHalf,
  falHSquare,
  falIdCard,
  falIdCardAlt,
  falInfoCircle,
  falInfoSquare,
  falIslandTropical,
  falKey,
  falLaptop,
  falLemon,
  falLifeRing,
  falLightbulb,
  falLock,
  falLockAlt,
  falMapMarkedAlt,
  falMapMarkerAlt,
  falMedkit,
  falMessageSms,
  falMobile,
  falMoneyBillWave,
  falMoneyCheckAlt,
  falMoon,
  falNewspaper,
  falPaperclip,
  falPaperPlane,
  falPhoneLaptop,
  falPlane,
  falPlaneAlt,
  falPlus,
  falPlusCircle,
  falPrintSearch,
  falQuestionCircle,
  falRabbitFast,
  falRaindrops,
  falRocket,
  falRss,
  falSackDollar,
  falSave,
  falSearch,
  falSearchDollar,
  falShieldCheck,
  falSignOut,
  falSlidersH,
  farSlidersV,
  falSnowflakes,
  falSolarSystem,
  falSpinner,
  falSquare,
  falStar,
  falStethoscope,
  falSubway,
  falSuitcase,
  falSun,
  falSunCloud,
  falSunHaze,
  falSunset,
  falTabletAlt,
  falTelescope,
  falThermometerHalf,
  falThLarge,
  falThumbtack,
  falTimes,
  falTimesCircle,
  falTooth,
  falTrashAlt,
  falTree,
  falTrophy,
  falTshirt,
  falUmbrellaBeach,
  falUser,
  falUserDoctor,
  falUserGraduate,
  falUserMd,
  falUserNurse,
  falUsers,
  falUsersMedical,
  falUtensilsAlt,
  falWalking,
  farAngleDown,
  farArrowDown,
  farArrowUp,
  farArrowUpFromBracket,
  farBullhorn,
  farCheck,
  farChevronLeft,
  falChevronDown,
  falChevronLeft,
  falChevronUp,
  farExclamationCircle,
  farExternalLink,
  farSearch,
  fasStars,
  farStars,
  farTachometerAltFastest,
  farTasks,
  farUnlockAlt,
  farUserCheck,
  fasAddressCard,
  fasAlarmClock,
  fasAngleDown,
  fasAngleLeft,
  fasAngleRight,
  fasAngleUp,
  fasBan,
  fasBars,
  fasBed,
  fasBell,
  fasBellOn,
  fasBicycle,
  fasBinoculars,
  fasBookHeart,
  fasBookmark,
  fasBooks,
  fasBriefcase,
  fasBriefcaseMedical,
  fasBuilding,
  fasBullhorn,
  fasBusinessTime,
  fasCalendar,
  fasCalendarAlt,
  fasCalendarCheck,
  fasCalendarDay,
  fasCalendarEdit,
  fasCar,
  fasCaretDown,
  fasCaretUp,
  fasChartPieAlt,
  fasCheck,
  fasCheckCircle,
  fasChevronRight,
  fasChevronDown,
  fasChevronUp,
  fasCircle,
  fasCircleInfo,
  falChevronCircleRight,
  falChevronCircleLeft,
  falCirclePlay,
  fasCirclePlay,
  fasClipboardCheck,
  fasClipboardList,
  fasClipboardListCheck,
  fasClock,
  fasCloud,
  fasCloudDownload,
  fasCloudUpload,
  fasCog,
  fasComment,
  fasCommentAltSmile,
  fasCommentDots,
  fasCompass,
  fasCopy,
  fasDollarSign,
  fasEclipse,
  fasEnvelope,
  fasEnvelopeOpenText,
  fasExclamation,
  fasExclamationCircle,
  fasExclamationTriangle,
  fasExternalLink,
  fasEye,
  fasFaPen,
  fasFaPencil,
  fasFile,
  fasFileAlt,
  fasFileCertificate,
  fasFileCheck,
  fasFileDownload,
  fasFileExclamation,
  fasFileFileMedical,
  fasFileImport,
  fasFileInvoiceDollar,
  fasFilesMedical,
  fasFileSignature,
  fasFilter,
  fasFlag,
  fasFlagCheckered,
  fasFlowerTulip,
  fasFolders,
  fasGift,
  fasGifts,
  fasGlassCheers,
  fasGlassesAlt,
  fasGlassMartini,
  fasGlobe,
  fasGlobeAmericas,
  fasGraduationCap,
  fasHandPaper,
  fasHeadset,
  fasHeart,
  fasHeartbeat,
  fasHistory,
  fasHome,
  fasHospital,
  fasHospitalAlt,
  fasHospitals,
  fasHospitalUser,
  fasHourglassHalf,
  fasHSquare,
  fasIdCard,
  fasIdCardAlt,
  fasInfoCircle,
  fasInfoSquare,
  fasIslandTropical,
  fasKey,
  fasLaptop,
  fasLemon,
  fasLifeRing,
  fasLightbulb,
  fasListCheck,
  fasLockAlt,
  fasMapMarkedAlt,
  fasMapMarkerAlt,
  fasMapPin,
  fasMedkit,
  fasMobile,
  fasMoneyBillWave,
  fasMoneyCheckAlt,
  fasMoon,
  fasMoonStars,
  fasNewspaper,
  fasPaperclip,
  fasPaperPlane,
  fasPhoneLaptop,
  fasPlane,
  fasPlaneAlt,
  fasPlus,
  fasPlusCircle,
  fasPrintSearch,
  fasQuestionCircle,
  fasRabbitFast,
  fasRaindrops,
  fasRocket,
  fasRss,
  fasSackDollar,
  fasSave,
  fasSearch,
  fasSearchDollar,
  fasShieldCheck,
  fasSignOut,
  fasSlidersH,
  fasSnowflakes,
  fasSolarSystem,
  fasSort,
  fasSpinner,
  fasSquare,
  fasStar,
  fasStethoscope,
  fasSubway,
  fasSuitcase,
  fasSun,
  fasSunCloud,
  fasSunHaze,
  fasSunset,
  fasTabletAlt,
  fasTelescope,
  fasThermometerHalf,
  fasThLarge,
  fasThumbtack,
  fasTimes,
  fasTimesCircle,
  fasTooth,
  fasTools,
  fasTrashAlt,
  fasTree,
  fasTrophy,
  fasTshirt,
  fasUmbrellaBeach,
  fasUser,
  fasUserClock,
  fasUsersCog,
  fasUserGraduate,
  fasUserMd,
  fasUserNurse,
  fasUsers,
  fasUsersMedical,
  fasUtensilsAlt,
  fasWalking,
  falCommentLines,
  fasBallotCheck,
  farPenToSquare,
  fasSparkles,
  fasArrowUpRightFromSquare,
  farListUl,
  farTrashAlt
);
export { library };
