import { replace } from 'lodash';

export const getIconForTopicId = (id: string) => {
  let iconName = 'info-square';
  switch (id) {
    case '3717245':
      iconName = 'clipboard-list';
      break;
    case '3717246':
      iconName = 'heartbeat';
      break;
    case '3778236':
      iconName = 'file-invoice-dollar';
      break;
    case '3717248':
      iconName = 'file-signature';
      break;
    case '3464937':
      iconName = 'exclamation-triangle';
      break;
    case '3717255':
      iconName = 'calendar-alt';
      break;
    case '3717256':
      iconName = 'alarm-clock';
      break;
    case '3717257':
      iconName = 'sack-dollar';
      break;
    case '3717226':
      iconName = 'info-circle';
      break;
    case '3717227':
      iconName = 'tools';
      break;
    case '3717228':
      iconName = 'users-cog';
      break;
    case '3717238':
      iconName = 'id-card';
      break;
    case '3717239':
      iconName = 'file-medical';
      break;
    case '3717240':
      iconName = 'search';
      break;
    case '3717241':
      iconName = 'folders';
      break;
    case '3717243':
      iconName = 'file-exclamation';
      break;
    case '3717244':
      iconName = 'file-download';
      break;
  }
  return iconName;
};

export const removeAmpersand = (item: string) => replace(item, /&amp;/g, '&');
