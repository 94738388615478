import DateFnsUtils from '@date-io/date-fns';
import type { Opaque } from 'ts-essentials';
import { format, isValid, parseISO } from 'date-fns';
import {
  GQL_DATE_FORMAT,
  GQL_DATE_TIME_FORMAT,
  type GraphQLISO8601Date,
  type GraphQLISO8601DateTime,
} from '@src/apollo/types';

// TypeScript allows passing our fake opaque types defined as `<Opaque<string, ...>>` as
// a `string` argument to methods, but we don't want to allow that in a lot of places.
// This type will allow only raw `string`s and not opaque types built on them.
// We can't really avoid this for `any` arguments though unfortunately.
type NotOpaque<T> = Partial<Opaque<T, 'NotOpaque'>>;

function pad(number: number) {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
}

class DateFnsPlus extends DateFnsUtils {
  isFutureDate(date: Date | string | null) {
    if (!this.isValid(date)) {
      throw new TypeError('isFutureDate must be given a valid date');
    }

    const now = new Date();
    return (
      this.isAfterDay(date as Date, now) || this.isSameDay(date as Date, now)
    );
  }

  isPastDate(date: Date | string | null) {
    if (!this.isValid(date)) {
      throw new TypeError('isPastDate must be given a valid date');
    }

    const now = new Date();
    return (
      this.isBeforeDay(date as Date, now) || this.isSameDay(date as Date, now)
    );
  }

  isValidTimeString(timeString: string | null) {
    const date = this.date(timeString);
    return this.isValid(date) && date.toISOString() === timeString;
  }

  isValidDateString(dateString: NotOpaque<string>) {
    const date = dateString as string;
    if (date.length !== 10) {
      return false;
    }

    const dateParts = date.split('-');

    if (dateParts.length !== 3) {
      return false;
    }

    if (dateParts[0].length !== 4 || isNaN(parseInt(dateParts[0]))) {
      return false;
    }

    if (dateParts[1].length !== 2 || isNaN(parseInt(dateParts[1]))) {
      return false;
    }

    if (dateParts[2].length !== 2 || isNaN(parseInt(dateParts[2]))) {
      return false;
    }

    return true;
  }

  parseDate(dateString?: Date | string | NotOpaque<string> | null) {
    if (!dateString) {
      return null;
    }

    const date = this.date(dateString);
    if (this.isValid(date)) {
      const timezoneOffset = date.getTimezoneOffset();
      return new Date(date.getTime() + timezoneOffset * 60 * 1000);
    }

    return new Date('');
  }

  parseTime(timeString: Date | string | NotOpaque<string> | null) {
    if (!timeString) {
      return null;
    }

    return this.date(timeString);
  }

  formatFromDateString(
    dateString: NotOpaque<string>,
    formatString: string,
    fallback = ''
  ) {
    const date = this.parseDate(dateString);
    if (!date) return null;

    return this.isValid(date) ? this.format(date, formatString) : fallback;
  }

  formatFromTimeString(
    timeString: NotOpaque<string>,
    formatString: string,
    fallback = ''
  ) {
    const date = this.parseTime(timeString);
    if (!date) return null;

    return this.isValid(date) ? this.format(date, formatString) : fallback;
  }

  dateStringFromDate(date: Date) {
    if (!this.isValid(date)) {
      throw new TypeError('dateStringFromDate must be given a valid date');
    }

    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
      date.getDate()
    )}`;
  }

  timeStringFromDate(date: Date) {
    if (!this.isValid(date)) {
      throw new TypeError('timeStringFromDate must be given a valid date');
    }

    return date.toISOString();
  }

  getDay(dateString?: NotOpaque<string>) {
    const date = this.parseDate(dateString);
    if (!date) return null;
    if (!this.isValid(date)) return '';

    return this.format(date, 'EEEE');
  }

  // Adjusts a date so conversion from TZ to UTC does not change the day
  adjustDateForUTC(date: Date) {
    if (!this.isValid(date)) {
      throw new TypeError('adjustDateForUTC must be given a valid date');
    }

    const dateAtMidnight = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    // If the time is behind UTC then just return as is since
    // midnight in local time will always be same day in UTC
    // Else if it is ahead adjust the time forward so UTC doesn't
    // fall back to the previous day
    const timezoneOffset = date.getTimezoneOffset() * 60 * 1000;
    if (timezoneOffset >= 0) {
      return dateAtMidnight;
    } else {
      return new Date(dateAtMidnight.getTime() - timezoneOffset);
    }
  }

  isISODate(date: NotOpaque<string>) {
    const str = date as string;
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    const d = new Date(str);
    return d.toISOString() === str;
  }

  formatGQLDate(date: GraphQLISO8601Date, formatString: string) {
    return this.format(gqlDateToDate(date), formatString);
  }

  toGQLDate(date: NotOpaque<string>): GraphQLISO8601Date {
    if (!this.isValidDateString(date)) {
      throw new RangeError(
        `Unexpected date format, expected ${GQL_DATE_FORMAT}: ${date}`
      );
    }
    return date as unknown as GraphQLISO8601Date;
  }

  toGQLDateTime(date: NotOpaque<string>): GraphQLISO8601DateTime {
    const dateTimeRegex =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}([-+]\d{2}:\d{2}|Z)$/;
    const d = date as string;
    if (!d.match(dateTimeRegex)) {
      throw new RangeError(
        `Unexpected datetime format, expected ${GQL_DATE_TIME_FORMAT}: ${date}`
      );
    }
    return d as GraphQLISO8601DateTime;
  }
}

/**
 * Parses an ISO8601Date from GraphQL into a Date
 */
export function gqlDateToDate(date?: null | undefined): null;
export function gqlDateToDate(date: GraphQLISO8601Date): Date;
export function gqlDateToDate(date?: GraphQLISO8601Date | null): Date | null;
export function gqlDateToDate(date?: GraphQLISO8601Date | null): Date | null {
  if (typeof date === 'undefined' || date === null) return null;
  const dateStr = date as unknown as string;
  if (!dateUtils.isValidDateString(dateStr)) {
    throw new RangeError(`Unexpected date format: ${dateStr}`);
  }

  const [yearStr, monthStr, dayStr] = dateStr.split('-');
  const outDate = new Date(
    parseInt(yearStr, 10),
    parseInt(monthStr, 10) - 1,
    parseInt(dayStr, 10),
    0,
    0,
    0,
    0
  );

  if (!isValid(outDate)) {
    throw new RangeError(`Unexpected date format: ${dateStr}`);
  }
  return dateUtils.adjustDateForUTC(outDate);
}

/**
 * Parses an ISO8601DateTime from GraphQL into a Date
 */
export function gqlDateTimeToDate(date?: null): null;
export function gqlDateTimeToDate(date: GraphQLISO8601DateTime): Date;
export function gqlDateTimeToDate(
  date?: GraphQLISO8601DateTime | null
): Date | null;
export function gqlDateTimeToDate(
  date?: GraphQLISO8601DateTime | null
): Date | null {
  if (typeof date === 'undefined' || date === null) return null;
  const dateStr = date as unknown as string;
  const outDate = parseISO(dateStr);

  if (!isValid(outDate)) {
    throw new RangeError(`Unexpected date format: ${date}`);
  }
  return outDate;
}

export function dateToGQLDate(date: Date): GraphQLISO8601Date {
  const adjustedDate = dateUtils.adjustDateForUTC(date);
  return format(adjustedDate, GQL_DATE_FORMAT) as GraphQLISO8601Date;
}

export function dateToGQLDateTime(date: Date): GraphQLISO8601DateTime {
  return format(date, GQL_DATE_TIME_FORMAT) as GraphQLISO8601DateTime;
}

export const dateUtils = new DateFnsPlus();
