export const CLICKED_BACK_BUTTON = 'User Clicked Back Button';
export const CLICKED_VIEW_MATCHES_BUTTON = 'User Clicked View Matches Button';
export const CLICKED_VIEW_APPLICATIONS_BUTTON =
  'User Clicked View Applications Button';
export const CLICKED_CLOSE_PANEL_BUTTON = 'User Clicked Close Panel Button';

export const CLICKED_NAV_INVITE_LINK = 'User Clicked Invite Link';
export const CLICKED_NAV_HOME_LINK = 'User Clicked Home Link';
export const CLICKED_NAV_PROFILE_LINK = 'User Clicked Profile Link';
export const CLICKED_NAV_MATCHES_LINK = 'User Clicked Matches Link';
export const CLICKED_NAV_CREDENTIALS_LINK = 'User Clicked Credentials Link';
export const CLICKED_NAV_APPLY_LINK = 'User Clicked Apply Link';
export const CLICKED_NAV_HELP_CENTER_LINK = 'User Clicked Help Center Link';
export const CLICKED_NAV_ACCOUNT_LINK = 'User Clicked Account Link';
export const CLICKED_NAV_LOGOUT_LINK = 'User Clicked Logout Link';
export const CLICKED_NAV_ONBOARDING_LINK = 'User Clicked Onboarding Link';
export const CLICKED_NAV_PAY_BENEFITS_LINK = 'User Clicked Pay Benefits Link';
export const CLICKED_NAV_EXPLORER_LINK = 'User Clicked Explorer Link';
export const CLICKED_NAV_COMMUNITY_LINK = 'User Clicked Community Link';
export const CLICKED_TOP_MENU_ITEM = 'User Clicked Top Menu Item';
